import { Typography } from '@mui/material'
import React from 'react'

const InformationWeReceiveFromOtherSources = () => {
    return (
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
            mt: '-5px',
            mb: '10px'
        }}>
            Due to the nature of the Services, which we provide, we are required to work with a number of third parties (including credit reference agencies and mobile network providers) and we may receive information about you from them. They may include:
            <ol>
                <li style={{fontWeight: 500}}>Third-Party Data</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                    Information from third parties, such as personal information or network friends, if you connect your account to the third party and grant the Application permission to access this information.
                </Typography>
                <li style={{fontWeight: 500}}>Data From Contests, Giveaways, and Surveys</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                }}>
                    Personal and other information you may provide when entering contests or giveaways and/or responding to surveys.
                </Typography>

            </ol>
        </Typography>
    )
}

export default InformationWeReceiveFromOtherSources