import { Typography } from '@mui/material'
import React from 'react'

const WhyWeCollectData = () => {
    return (
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
            mt: '-5px',
            mb: '10px'
        }}>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                We collect certain data to be able to provide the Service to you. Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. We also collect data for use in verifying your identity and creating credit-scoring models to determine what Loans can be offered to you. We also use this data for purposes of collections and credit reporting.
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
            }}>
                <span style={{fontWeight: 500}}>LEJA</span> uses the collected data for various purposes:
                <ul>
                    <li>To provide and maintain the Service.</li>
                    <li>To notify you about changes to our Service.</li>
                    <li>To allow you to participate in interactive features of our Service when you choose to do so.</li>
                    <li>To provide customer care and support.</li>
                    <li>To provide analysis or valuable information so that we can improve the Service.</li>
                    <li>To monitor the usage of the Service</li>
                    <li>To detect, prevent and address technical issues.</li>
                    <li>Administer sweepstakes, promotions, contests.</li>
                    <li>Compile anonymous statistical data and analysis for use internally or with third parties.</li>
                    <li>Create and manage your account.</li>
                    <li>Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Application to you.</li>
                    <li>Email you regarding your account or order</li>
                    <li>Fulfill and manage purchases, orders, payments, and other transactions related to the Application.</li>
                    <li>Generate a personal profile about you to make future visits to the Application more personalized.</li>
                    <li>Increase the efficiency and operation of the Application.</li>
                    <li>Monitor and analyze usage and trends to improve your experience with the Application</li>
                    <li>Notify you of updates to the Application.</li>
                    <li>Offer new products, services, mobile applications, and/or recommendations to you.</li>
                    <li>Perform other business activities as needed.</li>
                    <li>Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.</li>
                    <li>Process payments and refunds.</li>
                    <li>Request feedback and contact you about your use of the Application.</li>
                    <li>Resolve disputes and troubleshoot problems.</li>
                    <li>Respond to product and customer service requests.</li>
                    <li>Send you a newsletter.</li>
                    <li>Solicit support for the Application.</li>
                </ul>
            </Typography>
        </Typography>
    )
}

export default WhyWeCollectData