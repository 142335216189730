import { Typography } from '@mui/material'
import React from 'react'

const WhereWeStoreYourData = () => {
    return (
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
            mt: '-5px',
            mb: '10px'
        }}>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                The data that we collect from you may be transferred to, and stored at, a destination outside your country of origin or residence (as applicable) or other governmental jurisdiction where the data protection laws may differ from those from your jurisdiction.
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                If you are located outside KENYA and choose to provide information to us, please note that we transfer the data, including Personal Data, to KENYA and process it there.
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                We will transfer personal data out of Kenya only when they have:
                <ul>
                    <li>Proof of appropriate measures for security and protection of the personal data, and the proof provided to the Data Protection Commissioner in accordance with Kenya’s Data Protection Act, 2019, such measures include that data is transferred to jurisdictions with commensurate data protection laws.</li>
                    <li>
                        The transfer is necessary for the performance of a contract, implementation of pre- contractual measures such as:
                        <ul>
                            <li>For the conclusion or performance of a contract to which the data subject is part of.</li>
                            <li>For matters of public interest.</li>
                            <li>For legal claims.</li>
                        </ul>
                    </li>
                </ul>
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                To protect the vital interests of data subjects
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
            }}>
                Leja will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
            </Typography>
        </Typography>
    )
}

export default WhereWeStoreYourData