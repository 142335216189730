import { Typography } from '@mui/material'
import React from 'react'

const ChangesToThisPrivacyPolicy = () => {
  return (
    <Typography sx={{
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
        mt: '-5px',
        mb: '10px'
    }}>
        We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
    </Typography>
  )
}

export default ChangesToThisPrivacyPolicy