import { Typography, useTheme } from '@mui/material'
import React from 'react'

const InformationWeCollectAboutYou = () => {
    const theme = useTheme();
    return (
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
            mt: '-5px',
            mb: '10px'
        }}>
            We may collect information about you in a variety of ways. The information we may collect via the Application depends on the content and materials you use, and includes:
            <ol>
                <li>Personal Data</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                }}>
                    Personal Data, Sensitive Personal Data, Demographic and other personally identifiable information (such as your name and email address) that you voluntarily give to us when choosing to participate in various activities related to the Application, such as chat, posting messages in comment sections or in our forums, liking posts, sending feedback, and responding to surveys. If you choose to share data about yourself via your profile, online chat, or other interactive areas of the Application, please be advised that all data you disclose in these areas is public and your data will be accessible to anyone who accesses the Application.
                </Typography>
                <li>Derivative Data</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                }}>
                    Information our servers automatically collect when you access the Application, such as your native actions that are integral to the Application, including liking, re-blogging, or replying to a post, as well as other interactions with the Application and other users via server log files.
                </Typography>
                <li>Financial Data</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                }}>
                    Financial information, such as data related to your payment method (e.g. valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Application. [We store only very limited, if any, financial information that we collect. Otherwise, all financial information is stored by our payment processor, MPESA, and you are encouraged to review their privacy policy and contact them directly for responses to your questions.
                </Typography>
                <li>Facebook Permissions</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                }}>
                    The Application may by default access your <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0" target="_blank" rel="noopener noreferrer" style={{color: theme.palette.primary.main}}>Facebook</a> basic account information, including your name, email, gender, birthday, current city, and profile picture URL, as well as other information that you choose to make public. We may also request access to other permissions related to your account, such as friends, check ins, and likes, and you may choose to grant or deny us access to each individual permission. For more information regarding Facebook permissions, refer to the <a href="https://developers.facebook.com/docs/permissions" target="_blank" rel="noopener noreferrer" style={{color: theme.palette.primary.main}}>Facebook Permissions Reference page.</a>
                </Typography>
                <li>Data from Social Networks</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                }}>
                    User information from social networking sites, such as [Apple’s Game Center, Facebook, Google+ Instagram, Pinterest, Twitter], including your name, your social network username, location, gender, birth date, email address, profile picture, and public data for contacts, if you connect your account to such social networks. This information may also include the contact information of anyone you invite to use and/or join the Application.
                </Typography>
                <li>Geo-Location Information</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                }}>
                    We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using the Application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings.
                </Typography>
                <li>Mobile Device Access</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                }}>
                    We may request access or permission to certain features from your mobile device, including your mobile device’s [camera, contacts, SMS messages, social media accounts, storage, location] and other features.
                    <ul style={{margin: '10px 0px'}}>
                        <li>READ SMS permission is required to aid your loan appraisal during loan application. Upon the users’ consent, READ SMS permission is also required to enable the user to view a detailed transaction summary of MPESA transactions within the application when a transaction is completed. Leja application will only read and upload and update SMS originating from MPESA.</li>
                        <li>CAMERA permission will be required to upload demographic data during the loan application process which will be used in your loan appraisal. CAMERA permission is also required to enable the user to capture and upload photos of their businesses during the business wallet application.</li>
                        <li>READ CONTACT permission will be required if you wish to select a contact from your phone’s contact list to initiate a transaction within the application.</li>
                        <li>STORAGE permission is required to upload files through the application. STORAGE permission is also required to enable the user to upload photos of their businesses during the business wallet application.</li>
                    </ul>
                    If you wish to change our access or permissions, you may do so in your device’s settings.
                </Typography>
                <li>Mobile Device Data</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                }}>
                    Device information such as your mobile device ID number, model, and manufacturer, version of your operating system, phone number, country, location, and any other data you choose to provide.
                </Typography>
                <li>Push Notifications</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                }}>
                    We may request to send you push notifications regarding your account or the Application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.
                </Typography>
                <li>App background data</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                    Leja application will only read and upload and update SMS originating from MPESA for:
                    <ol>
                        <li>Credit scoring to determine your loan limit</li>
                        <li>Fraud detection</li>
                        <li>Displaying a financial summary of M-PESA transactions alongside Leja in-app transactions.</li>
                    </ol>
                    SMS data collection will begin upon user consent and every subsequent app launch.
                </Typography>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                }}>
                    Leja collects the device make and model when the app is in the foreground and is used in debugging errors that may occur within the application. All data collection requested will begin only upon user consent.
                </Typography>
            </ol>
        </Typography>
    )
}

export default InformationWeCollectAboutYou