import {Backdrop, Box, Grid, Modal, Typography} from '@mui/material'
import Blog from '../Blog/Blog'
import BlogHero from '../Blog/BlogHero'
import PlayButton from "../../assets/OurStories/PlayButton.png";
import React from "react";
import SharePost from "../BlogContent/SharePost";
import barcelona from '../../assets/Blog/barcelona.png';

export interface BlogContentProps{
    heroHeading: string;
    heroText: string;
    heroDate: string;
    heroImageUrl: string;

    contentHeading: string;
    content: Array<BlogContent | BlogContentMedia>;
}

interface BlogContent{
    heading?: string;
    headingLevel?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    content: string;
    isQuote?: boolean;
}

interface BlogContentMedia {
    imageUrl?: string;
    videoUrl?: string;
    caption?: string;
    credits?: string;
    altText?: string;
}

const BlogBarcelona = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    return (
        <>
            <BlogHero imageUrl={barcelona}
                      heading="MWC Barcelona 2024"
                      text="The event showcased how technologies like Internet of Things, AI, and Machine Learning (ML), is driving potential growth within the telecoms sector."
                      date=""
            />
            <Box
                sx={{
                    mt: '40px',
                    ml: '32px',
                    mr: '32px',
                    display: 'flex',
                }}
            >
                <Box sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block'
                    },
                    minWidth: {
                        md: '200px',
                        lg: '300px',
                        xl: '350px'
                    },
                    maxWidth: {
                        md: '300px',
                        lg: '400px',
                        xl: '500px'
                    }
                }}>
                </Box>
                <Grid item sm={12} md={5}>
                    <Typography
                        sx={{
                            color: '#012317',
                            fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
                            lineHeight: '100%',
                            letterSpacing: '-1.5px',
                            fontWeight: 500,
                            fontStyle: 'normal',
                            textTransform: 'uppercase',
                            mb: { md: '30px', sm: '30px', xs: '16px' }
                        }}>
                        Mobile World Congress 2024, Barcelona
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                        <Grid container rowSpacing={3}>
                            <Typography
                                sx={{
                                    fontSize: { xs: '16px', md: '1vw' },
                                    fontstyle: 'normal',
                                    fontWeight: '300',
                                    lineHeight: '130%',
                                    color: '#012317',
                                    pt: '15px',
                                    pb: '30px',
                                }}>
                                The MWC Barcelona 2024 event brought together industry leaders and participants from across the mobile ecosystem,
                                attracting over 70,000 attendees. The event served as a platform to demonstrate the potential for growth
                                within the telecoms sector as an ecosystem, highlighting the convergence of various technologies such as
                                the Internet of Things, AI, and machine learning. These technologies are now driving digital transformation
                                through different devices, services, and experiences, paving the way for innovation and advancement in the industry.
                            </Typography>

                            <Box sx={{
                                position: 'relative',
                                padding: { md: '30px', sm: '24px', xs: '16px' },
                                width: { sm: '100%', xs: '100%' },
                                bgcolor: '#FFF',
                                borderRadius: '20px',
                                mb: '15px'
                            }}>
                                <img
                                    src={barcelona}
                                    alt="Barcelona MWC 2024"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: 'cover'
                                    }}
                                    onClick={() => handleOpen()}
                                    loading={'lazy'}
                                />
                                <img
                                    src={PlayButton}
                                    alt="Play Button"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                    onClick={() => handleOpen()}
                                    loading={'lazy'}
                                />
                                <Modal
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative',
                                            background: 'linear-gradient(to bottom, rgba(240, 240, 240, 0.8), rgba(255, 255, 255, 0.95))',
                                        }}>
                                        <Backdrop
                                            open={open}
                                            onClick={handleClose}
                                        >
                                            <Box
                                                sx={{
                                                    bgcolor: '#FFF',
                                                    mx: { md: '261px', sm: 'auto', xs: 'auto' },
                                                    my: { md: '78px 81px', sm: 'auto', xs: 'auto' },
                                                    width: { md: 'calc(100% - 522px)', sm: '85%', xs: '85%' },
                                                    height: { md: 'calc(100% - 159px)', sm: 'calc(100% - 159px)', xs: '50%' },
                                                    padding: { md: '30px', sm: '24px', xs: '12px' },
                                                    borderRadius: '20px',
                                                }}>
                                                <iframe
                                                    width="100%"
                                                    height="100%"
                                                    src="https://www.youtube.com/embed/aDeBGp3mOVA"
                                                    title="MWC Barcelona 2024"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowFullScreen
                                                ></iframe>
                                            </Box>
                                        </Backdrop>
                                    </Box>
                                </Modal>
                            </Box>
                        </Grid>
                    </Box>
                    <Box sx={{
                        display: { md: 'inline-flex', sm: 'flex', xs: 'flex' },
                        alignItems: 'center',
                        gap: '17px',
                    }}>
                        <Typography sx={{
                            color: 'primary.main',
                            fontSize: { xs: '16px', md: '1rem' },
                            fontStyle: 'normal',
                            fontWeight: 300,
                            lineHeight: '130%',
                            marginRight: '17px',

                        }}>Share this post:</Typography>
                        <SharePost />
                    </Box>
                </Grid>
                <Box sx={{
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block'
                    },
                    minWidth: {
                        md: '200px',
                        lg: '300px',
                        xl: '350px'
                    },
                    maxWidth: {
                        md: '300px',
                        lg: '400px',
                        xl: '500px'
                    }
                }}>
                </Box>
            </Box>
            <Blog title='MORE NEWS' subtitle='[NEWS AND INSIGHTS]' parentSlug={'mwc-barcelona-2024'}/>
        </>
    );
};

export default BlogBarcelona