import { Typography } from '@mui/material'
import React from 'react'

const DataProtectionOfficer = () => {
  return (
    <Typography sx={{
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
        mt: '-5px',
        mb: '10px'
    }}>
        We have a designated Data Protection Officer (DPO). Accordingly, the DPO will:
        <ul>
            <li>Advise our staff on requirements for data protection, including data protection impact assessments.</li>
            <li>Ensure that we have complied with the legal requirements on data protection.</li>
            <li>Facilitate capacity building of staff involved in data processing operations.</li>
            <li>Cooperate with external regulators on matters relating to data protection.</li>
        </ul>
    </Typography>
  )
}

export default DataProtectionOfficer