import { Typography } from '@mui/material'
import React from 'react'

const LogData = () => {
    return (
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
            mt: '-5px',
            mb: '10px'
        }}>
            We want to inform you that whenever you use our Service, in the case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
        </Typography>
    )
}

export default LogData