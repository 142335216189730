import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import HomePage from './pages/HomePage/HomePage';
import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import LejaApp from './pages/LejaApp/LejaApp';
import AboutUs from './pages/AboutUs/AboutUs';
import OurStories from './pages/OurStories/OurStories';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import PrivacyPoilcy from './pages/PrivacyPoilcy/PrivacyPoilcy';
import NotFound from './pages/NotFound/NotFound';
import Blog from './pages/Blog/Blog';
import BlogContent from './components/BlogContent/BlogContent';
import IntroducingLeja from './components/BlogContent/IntroducingLeja';
import CreateBudget from './components/BlogContent/CreateBudget';
import RegisterBusiness from './components/BlogContent/RegisterBusiness';
import TwifEvent from './components/BlogContent/TwifEvent';
import Sankalp from './components/BlogContent/Sankalp';
import BlogContentV2 from "./components/BlogContent/BlogContentV2";
import {dataProtectioArticle, sampleArticle} from "./utils/articles";
import DataProtection from "./components/BlogContent/DataProtection";
import ComplaintsPolicy from "./components/ComplaintsPolicy/ComplaintsPolicy";
import BlogBarcelona from "./components/Blog/BlogBarcelona";

const theme = createTheme({
  palette: {
    primary: {
      main: "#009166",
      dark: "#012317",
      light: "#00C774",
    },
    secondary: {
      main: "#64002C",
      light: "#FFBDD4",
    },
  },
  typography: {
    fontFamily: [
      "Rubik"
    ].join(',')
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "about-us",
    element: <AboutUs />,
  },
  {
    path: "our-stories",
    element: <OurStories />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "leja-app",
        element: <LejaApp />,
      },
      
      {
        path: "blog",
        element: <Blog />,
        // children: [
        //   {
        //     path: "id",
        //     element: <BlogContent />,
        //   },
        // ]
      },
      {
        path: "terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPoilcy />,
      },
      {
        path: "complaints-policy",
        element: <ComplaintsPolicy />,
      },
      {
        path: "the-maverick",
        element: <BlogContent />,
      },
      {
        path: "introducing-leja",
        element: <IntroducingLeja />,
      },
      {
        path: "budgeting-made-simple",
        element: <CreateBudget />,
      },
      {
        path: "registering-a-business",
        element: <RegisterBusiness />,
      },
      {
        path: "leja-at-twif",
        element: <TwifEvent />,
      },
      {
        path: "leja-at-sankalp",
        element: <Sankalp />,
      },
      {
        path: "mwc-barcelona-2024",
        element: <BlogBarcelona />,
      },
      {
        path: "data-protection",
        element: <DataProtection />,
      },
      {
        path: "sample-dynamic",
        element: <BlogContentV2 article={sampleArticle} />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <CssBaseline />
  
  <ThemeProvider theme={theme}>
    <Box sx={{ bgcolor: "#F5F7F2" }}>
      <RouterProvider router={router} />
    </Box>
  </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();