import { Typography } from '@mui/material'
import React from 'react'

const OurPrinciples = () => {
    return (
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
            mt: '-5px',
            mb: '10px'
        }}>
            We will ensure that data is:
            <ul>
                <li>Processed lawfully, fairly and in a transparent manner and in line with the right to privacy.</li>
                <li>Collected only for specified, explicit and legitimate purposes and not further processed in a manner incompatible with that purpose.</li>
                <li>Adequate, relevant, and limited to what is necessary in relation to the purposes for which it is to be processed.</li>
                <li>Accurate and where necessary kept up to date.</li>
                <li>Not kept in a form which permits identification of data subjects for longer than is necessary for the purposes for which the data is processed.</li>
                <li>Processed in a manner that ensures its security using appropriate technical and organizational measures to protect against unauthorized or unlawful processing and accidental loss, destruction, or damage.</li>
                <li>Not transferred out of Kenya unless there is proof of adequate data safeguards/ measures or consent from the data subject.</li>
            </ul>
        </Typography>
    )
}

export default OurPrinciples