import { Typography } from '@mui/material'
import React from 'react'

const PolicyStatement = () => {
  return (
    <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mt: '-5px',
                mb: '10px'
    }}>
        We are committed to complying with all relevant Kenyan legislation and applicable global legislations. We recognize that the protection of individuals through lawful, legitimate, and responsible processing and use of their personal data is a fundamental human right. We will ensure that it protects the rights of data subjects and that the data it collects, and processes is done in line with the required legislation. Our staff must comply with this policy, breach of which could result in disciplinary action.
    </Typography>
  )
}

export default PolicyStatement