import { Typography, useTheme } from '@mui/material'
import React from 'react'

const OptionsRegardingInfo = () => {
    const theme = useTheme();
    return (
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
            mt: '-5px',
            mb: '10px'
        }}>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '130%',
                mb: '10x'
            }}>
                Account Information
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10x'
            }}>
                You may at any time review or change the information in your account or terminate your account by:
                <ul>
                    <li>Logging into your account settings and updating your account</li>
                    <li>Contacting us officially using our support email: <a href="mailto: support@leja.co.ke" target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.primary.light }}> support@leja.co.ke</a></li>
                </ul>
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10x'
            }}>
                Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
            </Typography>
        </Typography>
    )
}

export default OptionsRegardingInfo