import { Typography } from '@mui/material'
import React from 'react'

const ChildrensPrivacy = () => {
  return (
    <Typography sx={{
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
        mt: '-5px',
        mb: '10px'
    }}>
        Our Service does not address anyone under the age of 18 (“Children”). We do not knowingly collect personally identifiable information or market to children under the age of 18. If you are a parent or guardian and you are aware that your Child(ren) has/have provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
    </Typography>
  )
}

export default ChildrensPrivacy