import { Typography } from '@mui/material'
import React from 'react'

const MinimizationOfCollection = () => {
  return (
    <Typography sx={{
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
        mt: '-5px',
        mb: '10px'
    }}>
        We will not process any personal data for a purpose for which it did not obtain consent. Should such a need arise, then consent must be obtained from you. We will collect and process data that is adequate, relevant, and limited to what is necessary. Our staff must not access data which they are not authorized to access nor have a reason to access. Data must only be collected for the performance of duties and tasks; staff must not ask data subjects to provide personal data unless that is strictly necessary for the intended purpose. Staff must ensure that they delete, destroy, or anonymise any personal data that is no longer needed for the specific purpose for which they were collected.
    </Typography>
  )
}

export default MinimizationOfCollection