import { Typography } from '@mui/material'
import React from 'react'

const Consent = () => {
  return (
    <Typography sx={{
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
        mt: '-5px',
        mb: '10px'
    }}>
        You are required to give consent immediately you install the Leja app and before using the service on the Registration page shown below. Where necessary we will maintain adequate records to show that consent was obtained before processing your personal information. Data will not be processed after the withdrawal of your consent.
    </Typography>
  )
}

export default Consent