import { Typography } from '@mui/material'
import React from 'react'

const DisclosureOfData = () => {
    return (
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
            mt: '-5px',
            mb: '10px'
        }}>
            We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
            <ol>
                <li style={{ fontWeight: 500, marginBottom: '10px' }}>By Law or to Protect Rights</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                    If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation. This includes exchanging information with other entities for fraud protection and credit risk reduction.
                </Typography>
                <li style={{ fontWeight: 500, marginBottom: '10px' }}>Third-Party Service Providers</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                    We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
                </Typography>
                <li style={{ fontWeight: 500, marginBottom: '10px' }}>Marketing Communications</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                    With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.
                </Typography>
                <li style={{ fontWeight: 500, marginBottom: '10px' }}>Interactions with Other Users</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                    If you interact with other users of the Application, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, liking posts, following blogs.
                </Typography>
                <li style={{ fontWeight: 500, marginBottom: '10px' }}>Online Postings</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                    When you post comments, contributions or other content to the Applications, your posts may be viewed by all users and may be publicly distributed outside the Application in perpetuity.
                </Typography>
                <li style={{ fontWeight: 500, marginBottom: '10px' }}>Third-Party Advertisers</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                    We may use third-party advertising companies to serve ads when you visit the Application. These companies may use information about your visits to the Application and other websites that are contained in web cookies in order to provide advertisements about goods and services of interest to you.
                </Typography>
                <li style={{ fontWeight: 500, marginBottom: '10px' }}>Affiliates</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                    We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.
                </Typography>
                <li style={{ fontWeight: 500, marginBottom: '10px' }}>Business Partners</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                    We may share your information with our business partners to offer you certain products, services or promotions.
                </Typography>
                <li style={{ fontWeight: 500, marginBottom: '10px' }}>Offer Wall</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                    The Application may display a third-party-hosted “offer wall.” Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for acceptance and completion of an advertisement offer. Such an offer wall may appear in the Application and be displayed to you based on certain data, such as your geographic area or demographic information. When you click on an offer wall, you will leave the Application. A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account.
                </Typography>
                <li style={{ fontWeight: 500, marginBottom: '10px' }}>Social Media Contacts</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                    If you connect to the Application through a social network, your contacts on the social network will see your name, profile photo, and descriptions of your activity.
                </Typography>
                <li style={{ fontWeight: 500, marginBottom: '10px' }}>Other Third Parties</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                    We may share your information with advertisers and investors for the purpose of conducting general business analysis. We may also share your information with such third parties for marketing purposes, as permitted by law.
                </Typography>
                <li style={{ fontWeight: 500, marginBottom: '10px' }}>Sale or Bankruptcy</li>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                    mb: '10px'
                }}>
                   If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity. If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy.
                </Typography>
                <Typography sx={{
                    fontSize: '16px',
                    fontWeight: 300,
                    lineHeight: '130%',
                }}>
                    We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations. If you no longer wish to receive correspondence, emails or other communications from third parties, you are responsible for contacting the third party directly.
                </Typography>
            </ol>
        </Typography>
    )
}

export default DisclosureOfData