import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import Calque_1 from '../../assets/Calque_1.svg';
import Group_394 from '../../assets/Group_394.svg';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import styles from "./NavBar.module.css";
import CustomDrawer from './CustomDrawer';
import { Link } from '@mui/material';
import { playStoreLink } from '../utils/GetTheAppMobile/GetTheAppMobile';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

// secondary.light color hard-coded in background color
// not an MUI component
const StyledHashLink = styled(HashLink)`
    text-decoration: none;
    padding: 8px 15px;
    border-radius: 4px;
    margin: 0 1px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active {
        background-color: #FFB5CC;
    }

    &:hover {
        background-color: #FFB5CC;
    }
`;

const pageLinks = [
    {
        text: "Leja App",
        path: "/leja-app",
    },
    {
        text: "Our Stories",
        path: "/our-stories",
    },
    {
        text: "About Us",
        path: "/about-us",
    },
    {
        text: "Blog",
        path: "/blog",
    },
];

function NavBar() {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleOpenDrawer = () => {
        setIsDrawerOpen(true);
    };

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Box sx={{
            padding: {
                xs: "12px 16px",
                sm: "16px 24px",
                md: '16px 1.875vw 16px 1.875vw'
            },
            position: "sticky",
            top: 0,
            zIndex: 1000,
        }}>
            <AppBar position="static"
                sx={{
                    height: '76.102px',
                    borderRadius: '20px',
                    backgroundColor: '#FFF',
                    boxShadow: 'none'
                }}>
                <Toolbar disableGutters>
                    <Box sx={{
                        height: '24px',
                        marginBottom: '26.05px',
                        marginTop: '26.1px',
                        ml: '16px'
                    }}>
                        <RouterLink to={'/'}>
                            <img src={Calque_1} alt="Logo"  loading={'lazy'}/>
                        </RouterLink>
                    </Box>

                    {isMobile && (
                        // For mobile view, display only the logo and menu icon
                        <>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', mr: '16px' }}>
                                <IconButton
                                    onClick={handleOpenDrawer}
                                    sx={{
                                        padding: '0',
                                        width: '18.031px',
                                        height: '14.028px',
                                    }}
                                >
                                    <MenuIcon sx={{
                                        color: 'primary.main'
                                    }} />
                                </IconButton>
                            </Box>
                        </>
                    )}

                    {(isTablet && !(isMobile)) && (
                        // For tablet view, display only the menu icon and "Get the app" button
                        <>
                            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                <IconButton
                                    onClick={handleOpenDrawer}
                                    sx={{
                                        mr: '20px'
                                    }}
                                >
                                    <MenuIcon sx={{
                                        color: 'primary.main'
                                    }} />
                                </IconButton>
                                <Link underline="none"
                                    href={playStoreLink}
                                    target="_blank"
                                    rel="noopener">
                                    <Button
                                        variant='contained'
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textTransform: 'uppercase',
                                            width: '218px',
                                            height: '48px',
                                            padding: '0px 26px 0px 30px',
                                            alignItems: 'center',
                                            gap: '8px',
                                            mr: '16px',
                                            flexShrink: 0,
                                            "&:hover": {
                                                bgcolor: "primary.light"
                                            }
                                        }}
                                    >
                                        <Typography sx={{ color: '#F5F7F2', mr: '16px' }}>Get the app</Typography>
                                        <img src={Group_394} alt="playstore logo" loading={'lazy'}/>
                                    </Button>
                                </Link>
                            </Box>
                        </>
                    )}

                    {!(isMobile || isTablet) && (
                        // For larger screens, display all menu items
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexGrow: 1,
                            }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                ml: 'auto',
                                height: '48px'
                            }}>
                                {pageLinks.map((pagelink) => (
                                    <NavLink
                                        key={pagelink.text}
                                        to={pagelink.path}
                                        onClick={scrollToTop}
                                        style={{
                                            textDecoration: 'none',
                                            padding: "8px 15px",
                                            borderRadius: "4px",
                                            margin: "0 1px",
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        className={({ isActive, isPending }) =>
                                            isActive
                                                ? styles.active
                                                : isPending
                                                    ? styles.pending
                                                    : styles.default
                                        }>
                                        <Typography
                                            key={pagelink.text}
                                            component='span'
                                            sx={{
                                                mx: 2,
                                                color: '#012317',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                textTransform: 'uppercase',
                                                fontWeight: 500,
                                                lineHeight: 'normal',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {pagelink.text}
                                        </Typography>
                                    </NavLink>
                                ))}

                                <StyledHashLink to='#contact' smooth>
                                    <Typography
                                        component='span'
                                        sx={{
                                            mx: 2,
                                            color: '#012317',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            textTransform: 'uppercase',
                                            fontWeight: 500,
                                            lineHeight: 'normal',
                                            cursor: 'pointer',
                                            borderRadius: "4px",
                                        }}
                                    >
                                        Contact Us
                                    </Typography>
                                </StyledHashLink>

                            </Box>
                            <Box sx={{
                                ml: 'auto',
                                mr: '16px',
                                flexShrink: 0,
                            }}>
                                <Link underline="none"
                                    href={playStoreLink}
                                    target="_blank"
                                    rel="noopener">
                                    <Button
                                        variant='contained'
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            textTransform: 'uppercase',
                                            height: '48px',
                                            padding: '0px 26px 0px 30px',
                                            alignItems: 'center',
                                            "&:hover": {
                                                bgcolor: "primary.light",
                                                boxShadow: 'none',
                                            },
                                            boxShadow: 'none',
                                        }}
                                    >
                                        <Typography sx={{
                                            color: '#F5F7F2',
                                            mr: '16px',
                                            fontSize: '16px',
                                            fontWeight: 500
                                        }}>
                                            Get the app
                                        </Typography>
                                        <img
                                            src={Group_394}
                                            style={{ width: '20px', height: '20px' }}
                                            alt="playstore logo"
                                            loading={'lazy'}/>
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
            <CustomDrawer
                isDrawerOpen={isDrawerOpen}
                handleCloseDrawer={handleCloseDrawer}
                pageLinks={pageLinks}
            />
        </Box>
    );
}

export default NavBar;
