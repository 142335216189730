import {Box, Typography, Button, useMediaQuery, Grid} from '@mui/material'
import React from 'react';
import { Theme } from '@mui/material/styles';
import { useState } from "react";
import Jackson from '../../assets/jackson.jpg';
import Paul from '../../assets/Paul.jpg';
import Peter from '../../assets/OurStories/Peter.jpg';
import Testimonial5 from '../../assets/OurStories/testimonial_5.jpg';
import Testimonial4 from '../../assets/OurStories/testimonial_4.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Gallery1 from '../../assets/OurStories/Gallery1.png';
import Gallery2 from '../../assets/OurStories/Gallery2.png';
import Gallery3 from '../../assets/OurStories/Gallery3.png';
import Gallery4 from '../../assets/OurStories/Gallery4.png';
import Gallery5 from '../../assets/OurStories/Gallery5.png';
import Gallery6 from '../../assets/OurStories/Gallery6.png';
import Gallery7 from '../../assets/OurStories/Gallery7.png';
import Gallery8 from '../../assets/OurStories/Gallery8.jpeg';
import Gallery9 from '../../assets/OurStories/Gallery9.jpeg';
import Gallery10 from '../../assets/Paul.jpg';
import Modal from '@mui/material/Modal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Backdrop from '@mui/material/Backdrop';
import { Swipeable } from 'react-swipeable';
import TestimonialCard from "./TestimonialCard";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const OurStoriesTabs = () => {
    const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const [value, setValue] = React.useState(0);
    const handleChange = (newValue: number) => {
        setValue(newValue);
    };


    const [openImage, setOpenImage] = React.useState(false);
    const [selectedImage, setSelectedImage] = useState<number | null>(null);
    const handleOpenImage = (index: number) => {
        setSelectedImage(index);
        setOpenImage(true);
    };
    const handleCloseImage = () => setOpenImage(false);

    // Array of images to be looped through
    // Make sure the positioning of the image in the array is similar to the index value set on the image onClick function
    const images = [
        require('../../assets/OurStories/Gallery1.png'),
        require('../../assets/OurStories/Gallery2.png'),
        require('../../assets/OurStories/Gallery3.png'),
        require('../../assets/OurStories/Gallery4.png'),
        require('../../assets/OurStories/Gallery5.png'),
        require('../../assets/OurStories/Gallery6.png'),
        require('../../assets/OurStories/Gallery7.png'),
        require('../../assets/OurStories/Gallery8.jpeg'),
        require('../../assets/OurStories/Gallery9.jpeg'),
        require('../../assets/Paul.jpg')
    ];

    //Ensure the selected Item is not null
    const handleNextImage = () => setSelectedImage((selectedImage! + 1) % images.length);
    const handlePrevImage = () => setSelectedImage((selectedImage! - 1 + images.length) % images.length);

    return (
        <Box>
            <Box sx={{
                textTransform: "uppercase",
                display: "flex",
                flexDirection: { md: 'row', sm: 'row', xs: 'column' },
                columnGap: { md: '30px', sm: '24px', xs: '0' },
                rowGap: { md: '0px', sm: '0px', xs: '8px' },
                mb: { md: '16px', sm: '16px', xs: '12px' }
            }}>
                <Button
                    variant='contained'
                    onClick={() => handleChange(0)}
                    sx={{
                        width: { md: '362px', sm: '224px', xs: '100%' },
                        height: '48px',
                        ml: { md: '24.563vw', sm: '248px', xs: '0px' },
                        bgcolor: value === 0 ? '#FFB5CC' : 'white',
                        '&:hover': {
                            bgcolor: '#FFB5CC',
                            boxShadow: 'none',
                        },
                        boxShadow: 'none',
                        borderRadius: '4px',
                    }}
                >
                    <Typography sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        textTransform: 'uppercase',
                        color: '#012317'
                    }}>
                        Testimonials
                    </Typography>
                </Button>
                <Button
                    variant='contained'
                    onClick={() => handleChange(1)}
                    sx={{
                        width: { md: '362px', sm: '224px', xs: '100%' },
                        height: '48px',
                        mr: { md: '24.5vw', sm: '0px', xs: '0px' },
                        bgcolor: value === 1 ? '#FFB5CC' : 'white',
                        '&:hover': {
                            bgcolor: '#FFB5CC',
                            boxShadow: 'none',
                        },
                        boxShadow: 'none',
                        borderRadius: '4px'
                    }}
                >
                    <Typography sx={{
                        fontSize: '16px',
                        fontWeight: 500,
                        textTransform: 'uppercase',
                        color: '#012317'
                    }}>
                        photo & video galery
                    </Typography>
                </Button>
            </Box>
            <TabPanel value={value} index={0}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TestimonialCard  testimonial={{
                            name: 'Paul',
                            image: Paul,
                            text: "Leja has greatly helped me reduce on transaction costs. Additionally, when you use Leja to send money, you get 3 free transactions below Kes 1000 every day.",
                            position: 'Shop owner in Nairobi',
                            videoUrl: "https://www.youtube.com/embed/ojIqxu4I4ek?si=KDx35XE8vcslFGlz"
                        }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TestimonialCard  testimonial={{
                            name: 'Peter',
                            image: Peter,
                            text: "I use Leja to pay employees at work because Leja allows me to send money to many people at once. The App is easy to use and also cost-effective for business owners.",
                            position: 'Restaurant owner in Nairobi',
                            videoUrl: "https://www.youtube.com/embed/3r95bRvmbPk?si=I7tnf93M4wQDfx6_"
                        }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TestimonialCard  testimonial={{
                            name: 'Jackson',
                            image: Jackson,
                            text: "As an Uber driver, it's easy to mix business and personal finances. Leja has helped me to keep track of my transactions and better understand where my money goes through their easy-to-use bookkeeping feature",
                            position: 'Uber driver in Nairobi',
                            videoUrl: "https://www.youtube.com/embed/HltrrS9uRkE?si=tN4FQ8jMTu40B8j7"
                        }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TestimonialCard  testimonial={{
                            name: "Teresina Auma",
                            image: Testimonial5,
                            text: "Leja helps me absorb shock in case of an emergency and puts me on a track to gain independence and thrive at home and in the market",
                            position: "Fish monger in Gikomba",
                            videoUrl: "https://www.youtube.com/embed/EwKsCj08qjc"
                        }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TestimonialCard  testimonial={{
                            name: "Caroline",
                            image: Testimonial4,
                            text: "I love Leja because I save on transaction fees. By not paying transaction fees, I save money and re-invest it back into the business",
                            position: "Restaurant cashier in Nairobi",
                            videoUrl: "https://www.youtube.com/embed/wUafTVLmHCA"
                        }} />
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box>
                    <Box sx={{
                        mb: { md: '140px', sm: '100px', xs: '60px' },
                        display: 'flex',
                    }}>
                        <Box sx={{
                            width: { sm: '32.813vw', xs: '43.333vw' },
                            height: { sm: '631px', xs: '231px' },
                            mr: { md: '0px', sm: '3.776vw', xs: '4.44vw' }
                        }}>
                            <img
                                src={Gallery1}
                                alt="Gallery Image 1"
                                style={{
                                    width: (isMobile ? 'auto' : (isTablet && !isMobile) ? 'auto' : '14.5vw'),
                                    height: (isMobile ? '92px' : (isTablet && !isMobile) ? '158px' : '304px'),
                                    marginBottom: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : 'auto'),
                                    marginRight: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : '9vw'),
                                    objectFit: 'cover',
                                    borderRadius: '20px'
                                }}
                                onClick={() => handleOpenImage(0)}
                                loading={'lazy'}
                            />
                            <img
                                src={Gallery3}
                                alt="Gallery Image 3"
                                style={{
                                    height: (isMobile ? '92px' : (isTablet && !isMobile) ? '157px' : '0px'),
                                    display: (isMobile ? 'block' : (isTablet && !isMobile) ? 'block' : 'none'),
                                    marginTop: (isMobile ? '40px' : (isTablet && !isMobile) ? '310px' : '0px'),
                                    marginLeft: (isMobile ? 'auto' : (isTablet && !isMobile) ? 'auto' : '0px'),
                                    marginRight: '0px',
                                    objectFit: 'cover',
                                    borderRadius: '20px'
                                }}
                                onClick={() => handleOpenImage(2)}
                                loading={'lazy'}
                            />
                            <Modal
                                open={openImage}
                                onClose={handleCloseImage}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                        background: 'linear-gradient(to bottom, rgba(240, 240, 240, 0.8), rgba(255, 255, 255, 0.95))',
                                    }}>
                                    <Button
                                        onClick={handlePrevImage}
                                        variant='contained'
                                        sx={{
                                            position: 'absolute',
                                            left: { md: 30 },
                                            zIndex: 9999,
                                            width: '68px',
                                            height: '48px',
                                            bgcolor: '#F4EEE3',
                                            '&:hover': {
                                                bgcolor: '#FFFFFF'
                                            },
                                            display: { md: 'block', sm: 'none', xs: 'none' }
                                        }}>
                                        <ArrowBackIcon sx={{ color: 'primary.main' }} />
                                    </Button>
                                    <Swipeable
                                        onSwipedLeft={handleNextImage}
                                        onSwipedRight={handlePrevImage}
                                    >
                                        <Backdrop
                                            open={openImage}
                                            onClick={handleCloseImage}
                                        >
                                            <Box
                                                sx={{
                                                    mx: { md: '512px', sm: '24px', xs: '16px' },
                                                    my: { md: '38px', sm: '24px', xs: '16px' },
                                                    borderRadius: '20px',
                                                }}>
                                                {selectedImage !== null && (
                                                    <img
                                                        src={images[selectedImage]}
                                                        alt={`Gallery ${selectedImage + 1}`}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover'
                                                        }}
                                                        loading={'lazy'}
                                                    />
                                                )}
                                            </Box>
                                        </Backdrop>
                                    </Swipeable>
                                    <Button
                                        variant='contained'
                                        onClick={handleNextImage}
                                        sx={{
                                            position: 'absolute',
                                            right: { md: 30 },
                                            width: '68px',
                                            height: '48px',
                                            bgcolor: '#F4EEE3',
                                            '&:hover': {
                                                bgcolor: '#FFFFFF'
                                            },
                                            display: { md: 'block', sm: 'none', xs: 'none' }
                                        }}>
                                        <ArrowForwardIcon sx={{ color: 'primary.main' }} />
                                    </Button>
                                </Box>
                            </Modal>
                        </Box>
                        <Box sx={{
                            display: { md: 'flex' },
                        }}>
                            <img
                                src={Gallery2}
                                alt="Gallery Image 2"
                                style={{
                                    width: (isMobile ? 'auto' : (isTablet && !isMobile) ? 'auto' : '47.188vw'),
                                    height: (isMobile ? '231px' : (isTablet && !isMobile) ? '631px' : '996px'),
                                    objectFit: 'cover',
                                    borderRadius: '20px'
                                }}
                                onClick={() => handleOpenImage(1)}
                                loading={'lazy'}
                            />
                            <img
                                src={Gallery3}
                                alt="Gallery Image 3"
                                style={{
                                    width: (isMobile ? 'auto' : (isTablet && !isMobile) ? 'auto' : '14.5vw'),
                                    height: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : '309px'),
                                    marginTop: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : 'auto'),
                                    marginLeft: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : '10vw'),
                                    display: (isMobile ? 'none' : (isTablet && !isMobile) ? 'none' : 'block'),
                                    objectFit: 'cover',
                                    borderRadius: '20px'
                                }}
                                onClick={() => handleOpenImage(2)}
                                loading={'lazy'}
                            />
                        </Box>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', sm: 'row-reverse', xs: 'row-reverse' },
                        mb: { md: '140px', sm: '100px', xs: '60px' },
                    }}>
                        <img
                            src={Gallery4}
                            alt="Gallery Image 4"
                            style={{
                                width: (isMobile ? '23.889vw' : (isTablet && !isMobile) ? '22.396vw' : '30.813vw'),
                                height: (isMobile ? '113px' : (isTablet && !isMobile) ? '225px' : '655px'),
                                objectFit: 'cover',
                                borderRadius: '20px'
                            }}
                            onClick={() => handleOpenImage(3)}
                            loading={'lazy'}
                        />
                        <img
                            src={Gallery5}
                            alt="Gallery Image 5"
                            style={{
                                width: (isMobile ? '47.778vw' : (isTablet && !isMobile) ? '61.328vw' : '47.063vw'),
                                height: (isMobile ? '231px' : (isTablet && !isMobile) ? '623px' : '996px'),
                                marginRight: (isMobile ? 'auto' : (isTablet && !isMobile) ? 'auto' : '0px'),
                                marginLeft: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : 'auto'),
                                objectFit: 'cover',
                                borderRadius: '20px'
                            }}
                            onClick={() => handleOpenImage(4)}
                            loading={'lazy'}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: { md: 'row', sm: 'row-reverse', xs: 'row-reverse' },
                        mb: { md: '140px', sm: '100px', xs: '60px' },
                    }}>
                        <img
                            src={Gallery6}
                            alt="Gllery Image 6"
                            style={{
                                width: (isMobile ? '53.056vw' : (isTablet && !isMobile) ? '61.458vw' : '63.563vw'),
                                height: (isMobile ? '124px' : (isTablet && !isMobile) ? '306px' : '732px'),
                                marginRight: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : '9vw'),
                                objectFit: 'cover',
                                borderRadius: '20px'
                            }}
                            onClick={() => handleOpenImage(5)}
                            loading={'lazy'}
                        />
                        <img
                            src={Gallery7}
                            alt="Gallery Image 7"
                            style={{
                                width: (isMobile ? '19.444vw' : (isTablet && !isMobile) ? '16.146vw' : '22.313vw'),
                                height: (isMobile ? '92px' : (isTablet && !isMobile) ? '165px' : '475px'),
                                marginRight: (isMobile ? '18.889vw' : (isTablet && !isMobile) ? '16.406vw' : '0px'),
                                objectFit: 'cover',
                                borderRadius: '20px'
                            }}
                            onClick={() => handleOpenImage(6)}
                            loading={'lazy'}
                        />
                    </Box>
                    <Box sx={{
                        mb: { md: '140px', sm: '100px', xs: '60px' },
                        display: 'flex',
                        flexDirection: { md: 'row', sm: 'row-reverse', xs: 'row-reverse' },
                    }}>
                        <Box sx={{
                            width: { sm: '32.422vw', xs: '43.333vw' },
                            height: { sm: '631px', xs: '231px' },
                            ml: { md: '0px', sm: '3.255vw', xs: '4.444vw' }
                        }}>
                            <img
                                src={Gallery9}
                                alt="Gallery Image 9"
                                style={{
                                    width: (isMobile ? 'auto' : (isTablet && !isMobile) ? 'auto' : '14.5vw'),
                                    height: (isMobile ? '92px' : (isTablet && !isMobile) ? '165px' : '307px'),
                                    marginBottom: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : 'auto'),
                                    marginRight: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : '9vw'),
                                    marginLeft: (isMobile ? '19.444vw' : (isTablet && !isMobile) ? '13.021vw' : '0px'),
                                    objectFit: 'cover',
                                    borderRadius: '20px'
                                }}
                                onClick={() => handleOpenImage(8)}
                                loading={'lazy'}
                            />
                            <img
                                src={Gallery10}
                                alt="Gallery Image 10"
                                style={{
                                    width: (isMobile ? '70px' : (isTablet && !isMobile) ? '128px' : '0px'),
                                    height: (isMobile ? '92px' : (isTablet && !isMobile) ? '170px' : '0px'),
                                    display: (isMobile ? 'block' : (isTablet && !isMobile) ? 'block' : 'none'),
                                    marginTop: (isMobile ? '47px' : (isTablet && !isMobile) ? '291px' : '0px'),
                                    marginRight: '0px',
                                    objectFit: 'cover',
                                    borderRadius: '20px'
                                }}
                                onClick={() => handleOpenImage(9)}
                                loading={'lazy'}
                            />
                        </Box>
                        <Box sx={{
                            display: { md: 'flex' },
                        }}>
                            <img
                                src={Gallery8}
                                alt="Gallery Image 8"
                                style={{
                                    width: (isMobile ? '47.778vw' : (isTablet && !isMobile) ? '61.328vw' : '47.375vw'),
                                    height: (isMobile ? '231px' : (isTablet && !isMobile) ? '631px' : '1006px'),
                                    objectFit: 'cover',
                                    borderRadius: '20px'
                                }}
                                onClick={() => handleOpenImage(7)}
                                loading={'lazy'}
                            />
                            <img
                                src={Gallery10}
                                alt="Gallery Image 10"
                                style={{
                                    width: (isMobile ? 'auto' : (isTablet && !isMobile) ? 'auto' : '14.563vw'),
                                    height: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : '309px'),
                                    marginBottom: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : 'auto'),
                                    marginLeft: (isMobile ? '0px' : (isTablet && !isMobile) ? '0px' : '9vw'),
                                    display: (isMobile ? 'none' : (isTablet && !isMobile) ? 'none' : 'block'),
                                    objectFit: 'cover',
                                    borderRadius: '20px'
                                }}
                                onClick={() => handleOpenImage(9)}
                                loading={'lazy'}
                            />
                        </Box>
                    </Box>
                </Box>
            </TabPanel>
        </Box>
    )
}

export default OurStoriesTabs