import React from 'react'
import { Box, Typography } from '@mui/material';
import ComplainPolicyItem from "./ComplainPolicyItem";
import ComplaintPolicySection from "./ComplaintPolicySection";

const PrivacyPolicy = () => {

    return (
        <>
            <Box sx={{
                display: { md: 'flex', sm: 'block', xs: 'block' },
                ml: { md: '30px', sm: '24px', xs: '16px' },
                mr: { md: '30px', sm: '24px', xs: '16px' },
                mt: { md: '11.667vh', sm: '38px', xs: '62px' }
            }}>
                <Typography sx={{
                    textTransform: 'uppercase',
                    mr: { md: '113px', sm: '543px', xs: '151px' },
                    mb: { sm: '30px', xs: '24px' },
                    fontSize: { md: '0.875vw', sm: '14px', xs: '14px' },
                    fontWeight: 400,
                    width: { md: '283px' }
                }}>
                    Last updated September 20, 2023
                </Typography>
                <Typography sx={{
                    fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
                    fontWeight: 500,
                    lineHeight: '100%',
                    lineSpacing: '-1.5px',
                    textTransform: 'uppercase'
                }}>
                    COMPLAINTS POLICY AND PROCEDURE
                </Typography>
            </Box>
            <ComplaintPolicySection title={"Policy"}>
                <ComplainPolicyItem title={'Introduction'} index={1}>
                    <p>Leja Limited (Leja) is committed to providing a high quality experience for
                        all its stakeholders, through its range of financial services. The firm
                        encourages a positive environment in which informal contact and feedback
                        from stakeholders is welcomed and where complaints can be dealt with
                        effectively.</p>
                    <p>This Complaints Policy and Procedure outlines our strategic intent as well as
                        processes to be used when any stakeholder has cause for concern. Attention
                        is drawn to this document through our website and during internal
                        meetings.</p>
                </ComplainPolicyItem>

                <ComplainPolicyItem title={'Aims of the Policy'} index={2}>
                    <ol>
                        <li>To provide a clear framework to help anyone who is not satisfied with
                            our services to raise their concerns and to ensure that we respond
                            effectively.
                        </li>
                        <li>To ensure that we have systems in place to make improvements happen as a
                            result of a complaint.
                        </li>
                        <li>To encourage prompt resolution at an early informal stage.</li>
                        <li>To ensure that all complaints are dealt with seriously, fairly and
                            sensitively, with no resultant victimization of a complainant.
                        </li>
                        <li>To raise awareness on the policy and procedures and ensure that staff
                            understand the processes through appropriate training.
                        </li>
                        <li>To define responsibilities and allocate duties to individual members of
                            staff in relation to the procedures set out.
                        </li>
                    </ol>
                </ComplainPolicyItem>
                <ComplainPolicyItem title={'Scope'} index={3}>
                    <p>This document should be used by anyone who wishes to formally complain about our services, including customers, trainees, partners, suppliers, and other users of our services.</p>
                    <p>If anyone wishes to complain on the behalf of another party (including minors) (s)he must produce evidence that they have the authority to act on their behalf.</p>
                    <p>We have separate procedures for:</p>
                    <ol>
                        <li>Staff wishing to complain about any aspect of their employment. (This is covered in the HR policy manual);</li>
                        <li>Parties wishing to get feedback (comment, compliment or make suggestions) on our services: They should use our feedback systems found on our website (http://www.asilimia.co.ke/), at our reception (feedback cards) or by emailing  us through
                            <a href="mailto: support@leja.co.ke"> support@leja.co.ke</a>
                        </li>
                    </ol>
                </ComplainPolicyItem>
                <ComplainPolicyItem title={'Definitions'} index={4}>
                    <p>
                        We consider a complaint to be an expression of dissatisfaction by one or more members of the public about our action or inaction, or about the standard of service provided by or on behalf of our company.
                    </p>
                </ComplainPolicyItem>
                <ComplainPolicyItem title={'Our Roles and responsibilities'} index={5}>
                    <ol>
                        <li>We have a Quality Assurance (QA) Team that receives all formal complaints and is responsible for logging and monitoring the complaints in accordance with the procedures below. The QA Team is constituted  of an  independent Director (Chair), the CEO (secretary) and the manager responsible for subject being discussed.</li>
                        <li>All the our staff have a responsibility for receiving complaints, treating them seriously and dealing with them appropriately. Whenever possible, complaints should be dealt with informally and promptly. All complaints (formal and informal) received by a member of staff must be forwarded to the Chairman of the Quality Assurance Team for recording and action –
                            <a href="mailto:qa@asilimia.co.ke">qa@asilimia.co.ke</a>
                        </li>
                        <li>Our managers and directors have a responsibility to take a lead role in resolving complaints, through investigation (when appropriate) and responding to the complainant. They  are also responsible for resolving complaints that have reached the appeals stage.</li>
                    </ol>
                </ComplainPolicyItem>
                <ComplainPolicyItem title={'Confidentiality'} index={6}>
                    <p>
                        All complaints will be handled sensitively and with discretion. If anyone makes a complaint against a member of staff, that member of staff may be informed about the substance of the complaint so that they are in a position to make a response. If, in exceptional circumstances and for justifiable reasons, a complainant wishes to remain anonymous from the individual about whom the complaint is made, this may be considered, for example in cases of harassment.
                    </p>
                    <p>
                        We will not normally investigate anonymous or malicious complaints.
                    </p>
                </ComplainPolicyItem>
            </ComplaintPolicySection>

            <ComplaintPolicySection title={"Procedure"}>
                <ComplainPolicyItem title={'Informal complaints'} index={1}>
                    <ol>
                        <li>It is hoped that most complaints can be dealt with using the informal procedure.</li>
                        <li>Concerns should be raised in the first instance with the person or area concerned as soon as possible, and not later than three months after the incident. If appropriate, a meeting will be offered between the person complaining and the area they are complaining about to arrive at an agreed resolution.</li>
                        <li>If a complaint is about a member of staff it should be referred to the relevant line manager.</li>
                        <li>At this informal stage complaints may be made in person, by phone or by email.</li>
                        <li>All informal complaints, even when satisfactorily resolved, must internally be forwarded to the Quality Assurance Team to be logged as part of on-going service monitoring and continuous improvement.</li>
                    </ol>
                </ComplainPolicyItem>

                <ComplainPolicyItem title={'Formal complaints procedure'} index={2}>
                    <p>
                        Where complaints are very serious or the matter has not been resolved informally, the complainant should raise a formal complaint under the following procedure.
                    </p>
                    <p>
                        NB: Complaints made more than three months after the incident will not normally be investigated unless there is a good explanation for this long lapse in time.
                    </p>
                    <ol>
                        <li><b>Filling out a complaints form:</b> The complainant shall fill out a Complaints Form having read the Complaints Policy and Procedure. The form shall be returned to Reception or to the Quality Assurance Team at the firm’s headquarters at Ikigai, Peponi Road, Nairobi or emailed to the quality assurance team through
                            <a href="mailto:qa@asilimia.co.ke">qa@asilimia.co.ke</a>
                        </li>
                        <li><b>Acknowledgment: </b> the Quality Assurance Team will send an acknowledgment (normally within 3 working days), stating who will be investigating the complaint and giving a firm commitment to respond - within 14 working days - to allow for an in-depth investigation.</li>
                        <li><b>Logging the complaint:</b> the Quality Assurance Team logs all complaints in a register (a spreadsheet of all complaints and their progress) which is circulated to management for review and their information/comments).</li>
                        <li><b>Investigation of the complaint:</b> the Quality Assurance Team forwards the complaint (with an Investigation (Appendix 2), clearly identifying timescales) to the most appropriate senior member of staff, normally the Manager responsible for the department. An independent manager/director may be asked to conduct the investigation if appropriate. During this stage the complainant may be contacted for further information or to be invited to meet with the investigating manager (They may be accompanied by a friend or family member. An accompanying person cannot take active part in the proceedings but can offer support to the complainant). </li>
                        <li><b>Resolution: </b>
                            the investigating manager will consider the complaint thoroughly and will return the completed investigation form to the Quality Assurance Team.
                            <p>
                                Based on the facts from the investigation coupled with the recommendations of the investigation manager, the Quality Assurance team will make a judgement to either:
                            </p>
                            <ul>
                                <li>Dismiss the complaint as unfounded, giving reasons.</li>
                                <li>Uphold or partially uphold the complaint, propose an amicable settlement, take appropriate steps to redress the issue and to avoid a similar problem arising in future.</li>
                            </ul>
                            <p>
                                The investigation outcome will normally be communicated to the complainant by the CEO while a copy of the letter will be filed by the Quality Assurance Team.
                            </p>
                        </li>
                        <li><b>Quality improvement: </b> the QA team will ensure that relevant action is taken to change procedures or implement staff training to prevent recurrence of the complaint. </li>
                        <li>
                            <b>
                                Monitoring the complaint:
                            </b>
                            the QA Team will monitor all logged complaints to ensure they are resolved within the allotted 14 working days. A member of the QA Team will inform the investigating manager of any unresolved complaints and will notify the complainant if they are unable to meet the agreed timescale – requesting for a further indulgence to finish the investigation.
                        </li>
                        <li>
                            <b>
                                Evaluation:
                            </b>
                            a record of complaints, appeals and outcomes are recorded on a complaints database (in excel). These will be analysed, summarised and presented to all department heads on a regular basis to ensure actions are carried out to address the issues raised.
                        </li>
                    </ol>

                </ComplainPolicyItem>

                <ComplainPolicyItem title={'Appeals'} index={3}>
                    <ol>
                        <li>
                            If the complainant is dissatisfied with the response they receive as an outcome of the investigation, they may appeal to the CEO (within a further 14 working days of the date on the response letter).
                        </li>
                        <li>
                            The appeal should be made in writing stating reasons for the appeal and any action they are seeking.
                        </li>
                        <li>
                            The CEO will constitute an Appeal Committee made up of the CEO, one Director and a senior Manager to investigate the complaint and decide to:
                            <ul>
                                <li>Uphold the original decision/dismiss the complaint as unfounded.</li>
                                <li>Uphold or partially uphold the complaint, recommend appropriate steps are taken to address the issue and to avoid a similar problem arising in future.</li>
                                <li>Invite the complainant to attend the appeal hearing (they may be accompanied by a friend or family member (An accompanying person cannot take an active part in the proceedings but can offer support to the complainant).</li>
                            </ul>
                        </li>
                        <li>
                            A written notification of the result of the appeal will normally be sent within 14 working days of the firm receiving the appeal, or within 5 working days of an appeal hearing.
                        </li>
                        <li>
                            The decision of the Appeals Committee (communicated by the CEO) is final.
                        </li>
                    </ol>
                </ComplainPolicyItem>

            </ComplaintPolicySection>

        </>

    )
}

export default PrivacyPolicy
