import { Typography } from '@mui/material'
import React from 'react'

const TrackingTechnologies = () => {
  return (
    <Typography sx={{
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
        mt: '-5px',
        mb: '10px'
    }}>
        <ol>
            <li style={{marginBottom : '10px', fontWeight: 500}}>Cookies and Web Beacons</li>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
            We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Application to help customize the Application and improve your experience. When you access the Application, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Application. You may not decline web beacons. However, they can be rendered ineffective by declining all cookies or by modifying your web browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline cookies on an individual basis.
            </Typography>
            <li style={{marginBottom : '10px', fontWeight: 500}}>Internet-Based Advertising</li>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                Additionally, we may use third-party software to serve ads on the Application, implement email marketing campaigns, and manage other interactive marketing initiatives. This third-party software may use cookies or similar tracking technology to help manage and optimize your online experience with us. For more information about opting-out of interest-based ads, visit the Network Advertising Initiative Opt-Out Tool or Digital Advertising Alliance Opt-Out Tool.
            </Typography>
            <li style={{marginBottom : '10px', fontWeight: 500}}>Website Analytics</li>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                We may also partner with selected third-party vendors[, such as [Adobe Analytics,] [Clicktale,] [Clicky,] [Cloudfare,] [Crazy Egg,] [Flurry Analytics,] [Google Analytics,] [Heap Analytics,] [Inspectlet,] [Kissmetrics,] [Mixpanel,] [Piwik,] and others], to allow tracking technologies and remarketing services on the Application through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Application, determine the popularity of certain content, and better understand online activity. By accessing the Application, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, if you do not want any information to be collected and used by tracking technologies, you can install and/or update your settings for one of the following:
                <ul style={{margin : '10px 0px'}}>
                    <li>[Adobe Privacy Choices Page]</li>
                    <li>[Clicktale Opt-Out Feature]</li>
                    <li>[Crazy Opt-Out Feature]</li>
                    <li>Digital Advertising Alliance Opt-Out Tool</li>
                    <li>[Flurry Analytics Yahoo Opt-Out Manager]</li>
                    <li>[Google Analytics Opt-Out Plugin]</li>
                    <li>[Google Ads Settings Page]</li>
                    <li>[Inspectlet Opt-Out Cookie]</li>
                    <li>[Kissmetrics Opt-Out Feature]</li>
                    <li>[Mixpanel Opt-Out Cookie]</li>
                    <li>Network Advertising Initiative Opt-Out Tool</li>
                </ul>
                You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.
            </Typography>
        </ol>
    </Typography>
  )
}

export default TrackingTechnologies