import { Typography } from '@mui/material'
import React from 'react'

const AccuracyOfData = () => {
  return (
    <Typography sx={{
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
        mt: '-5px',
        mb: '10px'
    }}>
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
            mb: '10px'
        }}>
            We shall ensure that the personal data we collect and process is accurate, kept up to date, corrected or deleted without delay. All relevant records must be updated should staff be notified of inaccuracies. Inaccurate or out of date records must be deleted or destroyed.
        </Typography>
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '130%',
            mb: '10px'
        }}>
            Data protection impact assessment
        </Typography>
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
            mb: '10px'
        }}>
            We will undertake a data protection impact assessment whenever we identify that the processing of personal information will likely result in a high risk to your rights and freedoms.
        </Typography>
    </Typography>
  )
}

export default AccuracyOfData