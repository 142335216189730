import { Link, Typography } from '@mui/material'
import React from 'react'

const ContactUs = () => {
    return (
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
            mt: '-5px',
            mb: '10px'
        }}>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                If you have questions or comments about this Privacy Policy, please contact us at:
                <ul>
                    <li>Noting your preferences at the time you register your account with the application</li>
                    <li>Logging into your account settings and updating your preferences.</li>
                    <li>Contacting us using the contact information provided below:</li>
                </ul>
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                Leja
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                24 Chalbi Drive, Lavington, Nairobi
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                <Link href="tel:++254111052280" sx={{ color: "primary.main" }}>
                    +254 111 052280
                </Link>
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                <Link href="mailto: support@leja.co.ke" sx={{ color: "primary.main"}}>
                support@leja.co.ke
                </Link>
            </Typography>

        </Typography>
    )
}

export default ContactUs