import { Typography } from '@mui/material'
import React from 'react'

const Introduction = () => {
    return (
        <>
            <Typography sx={{
                mt: '-5px',
                mb: '10px',
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
            }}>
                Leja mobile application (the Service).
            </Typography>
            <Typography sx={{
                mb: '10px',
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
            }}>
                This Policy is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal
                Information if anyone decided to use our Service. If you choose to use our Service, then you agree to the collection
                and use of information in relation to this policy. The Personal Information that we collect is used for providing and
                improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
            </Typography>
            <Typography sx={{
                mb: '10px',
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
            }}>
                The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible herein unless
                otherwise defined in this Privacy Policy
            </Typography>
            <Typography sx={{
                mb: '10px',
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
            }}>
                Personal data means any information relating to an identified or identifiable natural person.
            </Typography>
            <Typography sx={{
                mb: '10px',
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
            }}>
                Sensitive personal data means data that reveals the natural person’s race, health status, ethnic, social origin, conscience, belief, genetic data, biometric data, property details, marital status, family details including names of the person’s children, parents, spouse or spouses sex, or the sexual orientation of the data subject.
            </Typography>
            <Typography sx={{
                mb: '10px',
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
            }}>
                Processing data means any operation or sets of operations performed on personal data whether or not by automated means, such as;
                <ul>
                    <li>collection, recording, organization, structuring;</li>
                    <li>storage, adaptation or alteration;</li>
                    <li>retrieval, consultation or use;</li>
                    <li>disclosure by transmission, dissemination, or otherwise making available; or</li>
                    <li>alignment or combination, restriction, erasure or destruction.</li>
                </ul>
            </Typography>
        </>

    )
}

export default Introduction