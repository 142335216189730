import { Typography } from '@mui/material'
import React from 'react'

const OnwardReporting = () => {
  return (
    <Typography sx={{
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
        mt: '-5px',
        mb: '10px'
    }}>
        In line with regulatory requirements, we will report to the Data Protection Commissioner and/or the relevant authorities any data breach within 72 hours of being aware. We will also communicate the data breach to the data subject as soon as is practical unless the identity of the data subject cannot be established.
    </Typography>
  )
}

export default OnwardReporting