import { Typography } from '@mui/material'
import React from 'react'

const EmailsAndCommunication = () => {
  return (
    <Typography sx={{
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
        mt: '-5px',
        mb: '10px'
    }}>
        If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:
        <ul>
            <li>Noting your preferences at the time you register your account with the Application</li>
            <li>Logging into your account settings and updating your preferences.</li>
            <li style={{marginBottom: '10px'}}>Contacting us using the contact information provided below</li>
            If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.
        </ul>
    </Typography>
  )
}

export default EmailsAndCommunication