import { Typography } from '@mui/material'
import React from 'react'

const ProcessingSensitivePersonalData = () => {
    return (
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
            mt: '-5px',
            mb: '10px'
        }}>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                We will process sensitive personal data only when:
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                The processing is carried out in the course of legitimate activities with appropriate safeguards and that the processing relates solely to the staff or to persons who have regular contact with us, and the personal data is not disclosed outside Leja without the consent of the data subject.
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
                mb: '10px'
            }}>
                The processing relates to personal data that has been made public by the data subject.
            </Typography>
            <Typography sx={{
                fontSize: '16px',
                fontWeight: 300,
                lineHeight: '130%',
            }}>
                Processing is necessary for:
                <ul>
                    <li>The establishment, exercise or defense of a legal claim</li>
                    <li>The purpose of carrying out the obligations and exercising specific rights of the controller or of the data subject</li>
                    <li>Protecting the vital interests of the data subject or another person where the data subject is physically or legally incapable of giving consent.</li>
                </ul>
            </Typography>
        </Typography>
    )
}

export default ProcessingSensitivePersonalData