import { Typography } from '@mui/material'
import React from 'react'

const LegalRequirements = () => {
  return (
    <Typography sx={{
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
        mt: '-5px',
        mb: '10px'
    }}>
        <span style={{fontWeight: 500}}>Leja</span> may disclose your Personal Data in the good faith belief that such action is necessary to:
        <ul>
            <li>To comply with a legal obligation</li>
            <li>To protect and defend the rights or property of <span style={{fontWeight:500}}>Leja</span></li>
            <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
            <li>To protect against legal liability</li>
        </ul>
    </Typography>
  )
}

export default LegalRequirements