import React, { useRef, useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, IconButton, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Introduction from './Introduction';
import PolicyStatement from './PolicyStatement';
import OurPrinciples from './OurPrinciples';
import InformationWeCollectAboutYou from './InformationWeCollectAboutYou';
import InformationWeReceiveFromOtherSources from './InformationWeReceiveFromOtherSources';
import WhyWeCollectData from './WhyWeCollectData';
import ProcessingSensitivePersonalData from './ProcessingSensitivePersonalData';
import WhereWeStoreYourData from './WhereWeStoreYourData';
import Consent from './Consent';
import LogData from './LogData';
import DisclosureOfData from './DisclosureOfData';
import LegalRequirements from './LegalRequirements';
import TrackingTechnologies from './TrackingTechnologies';
import ThirdPartyWebsites from './ThirdPartyWebsites';
import Security from './Security';
import ServiceProviders from './ServiceProviders';
import ChangesToThisPrivacyPolicy from './ChangesToThisPrivacyPolicy';
import ChildrensPrivacy from './ChildrensPrivacy';
import DoNotTrackControls from './DoNotTrackControls';
import OptionsRegardingInfo from './OptionsRegardingInfo';
import EmailsAndCommunication from './EmailsAndCommunication';
import OnwardReporting from './OnwardReporting';
import DataProtectionOfficer from './DataProtectionOfficer';
import MinimizationOfCollection from './MinimizationOfCollection';
import AccuracyOfData from './AccuracyOfData';
import Disclaimer from './Disclaimer';
import ContactUs from './ContactUs';

const PrivacyPolicy = () => {
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);

        if (isExpanded) {
            const summaryElement = document.getElementById(`${panel}-summary`);
            //console.log(summaryElement)
            if (summaryElement) {
                setTimeout(() => {
                    summaryElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }, 500);
            }
        }
    };

    return (
        <>
            <Box sx={{
                display: { md: 'flex', sm: 'block', xs: 'block' },
                ml: { md: '30px', sm: '24px', xs: '16px' },
                mr: { md: '30px', sm: '24px', xs: '16px' },
                mt: { md: '11.667vh', sm: '38px', xs: '62px' }
            }}>
                <Typography sx={{
                    textTransform: 'uppercase',
                    mr: { md: '113px', sm: '543px', xs: '151px' },
                    mb: { sm: '30px', xs: '24px' },
                    fontSize: { md: '0.875vw', sm: '14px', xs: '14px' },
                    fontWeight: 400,
                    width: { md: '283px' }
                }}>
                    Last updated September 20, 2023
                </Typography>
                <Typography sx={{
                    fontSize: { md: '3.125vw', sm: '50px', xs: '30px' },
                    fontWeight: 500,
                    lineHeight: '100%',
                    lineSpacing: '-1.5px',
                }}>
                    DATA PRIVACY POLICY
                </Typography>
            </Box>
            <Box sx={{
                mr: { md: '30px', sm: '24px', xs: '16px' },
                ml: { md: '30px', sm: '24px', xs: '16px' },
                mb: { md: '15.556vh', sm: '100px', xs: '58px' },
                mt: { md: '10.444vh', sm: '72px', xs: '40px' },
                borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                borderBottom: '1px solid rgba(1, 35, 23, 0.15)',
            }}>
                <Accordion
                    expanded={expanded === `panel${1}`}
                    onChange={handleChange(`panel${1}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent'
                    }}
                >
                    <AccordionSummary
                        id={`panel${1}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${1}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(0 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Introduction
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '110px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <Introduction />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${2}`}
                    onChange={handleChange(`panel${2}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${2}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${2}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(1 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Policy Statement
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '110px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0,
                    }}>
                        <PolicyStatement />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${3}`}
                    onChange={handleChange(`panel${3}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${3}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${3}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(2 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Our Principles
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '110px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0,
                    }}>
                        <OurPrinciples />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${4}`}
                    onChange={handleChange(`panel${4}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${4}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${4}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(3 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Information we collect about you and your device
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                    }}>
                        <InformationWeCollectAboutYou />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${5}`}
                    onChange={handleChange(`panel${5}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${5}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${5}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(4 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Information we receive from other sources
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <InformationWeReceiveFromOtherSources />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${6}`}
                    onChange={handleChange(`panel${6}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${6}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${6}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(5 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Why we collect data
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <WhyWeCollectData />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${7}`}
                    onChange={handleChange(`panel${7}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${7}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${7}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(6 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            Processing sensitive personal data
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <ProcessingSensitivePersonalData />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${8}`}
                    onChange={handleChange(`panel${8}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${8}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${8}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(7 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            where we store your personal data
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <WhereWeStoreYourData />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${9}`}
                    onChange={handleChange(`panel${9}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${9}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${9}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(8 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            consent
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <Consent />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${10}`}
                    onChange={handleChange(`panel${10}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${9}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${10}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(9 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            log data
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <LogData />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${11}`}
                    onChange={handleChange(`panel${11}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${11}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${11}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(10 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            disclosure of data
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <DisclosureOfData />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${12}`}
                    onChange={handleChange(`panel${12}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${12}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${12}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(11 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            legal requirements
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <LegalRequirements />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${13}`}
                    onChange={handleChange(`panel${13}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${13}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${13}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(12 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            tracking technologies
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <TrackingTechnologies />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${14}`}
                    onChange={handleChange(`panel${14}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${14}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${14}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(13 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            third party websites
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <ThirdPartyWebsites />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${15}`}
                    onChange={handleChange(`panel${15}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${15}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${15}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(14 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            security
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <Security />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${16}`}
                    onChange={handleChange(`panel${16}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${16}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${16}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(15 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            service providers
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <ServiceProviders />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${17}`}
                    onChange={handleChange(`panel${17}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${17}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${17}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(16 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            changes to this privacy policy
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <ChangesToThisPrivacyPolicy />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${18}`}
                    onChange={handleChange(`panel${18}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${18}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${18}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(17 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            children's privacy
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <ChildrensPrivacy />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${19}`}
                    onChange={handleChange(`panel${19}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${19}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${19}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(18 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            controls for do-not-track features
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <DoNotTrackControls />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${20}`}
                    onChange={handleChange(`panel${20}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${20}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${20}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(19 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            options regarding your information
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <OptionsRegardingInfo />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${21}`}
                    onChange={handleChange(`panel${21}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${21}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${21}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(20 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            emails and communications
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <EmailsAndCommunication />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${22}`}
                    onChange={handleChange(`panel${22}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${22}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${22}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(21 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            onward reporting
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <OnwardReporting />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${23}`}
                    onChange={handleChange(`panel${23}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${23}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${23}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(22 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            data protection officer
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <DataProtectionOfficer />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${24}`}
                    onChange={handleChange(`panel${24}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${24}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${24}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(23 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            minimization of collection
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <MinimizationOfCollection />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${25}`}
                    onChange={handleChange(`panel${25}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${25}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${25}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(24 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            accuracy of data
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <AccuracyOfData />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${26}`}
                    onChange={handleChange(`panel${26}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${26}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${26}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(25 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            disclaimer
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <Disclaimer />
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === `panel${27}`}
                    onChange={handleChange(`panel${27}`)}
                    sx={{
                        boxShadow: 'none',
                        '&.Mui-expanded': {
                            marginBottom: 0,
                            marginTop: 0
                        },
                        background: 'transparent',
                        borderTop: '1px solid rgba(1, 35, 23, 0.15)',
                    }}
                >
                    <AccordionSummary
                        id={`panel${27}-summary`}
                        sx={{ paddingLeft: 0 }}
                        expandIcon={
                            <IconButton>
                                {expanded === `panel${27}` ? <RemoveIcon /> : <AddIcon />}
                            </IconButton>
                        }
                    >
                        <Typography sx={{
                            mr: { md: '385px', sm: '94px', xs: '20px' },
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {String(26 + 1).padStart(2, '0')}
                        </Typography>
                        <Typography sx={{
                            fontSize: { md: '21px', sm: '21px', xs: '18px' },
                            fontWeight: 500,
                            lineHeight: '120%',
                            letterSpacing: ' -0.54px',
                            textTransform: 'uppercase',
                        }}>
                            contact us
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        ml: { md: '402px', sm: '115px', xs: '37px' },
                        width: { md: '625px', sm: '470px', xs: '240px' },
                        padding: 0
                    }}>
                        <ContactUs />
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>

    )
}

export default PrivacyPolicy
