import { Typography } from '@mui/material'
import React from 'react'

const Security = () => {
  return (
    <Typography sx={{
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '130%',
        mt: '-5px',
        mb: '10px'
    }}>
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
            mb:'10px'
        }}>
        Personal Information will be protected by security safeguards that are appropriate to the sensitivity level of the information will protect personal Information. We take all reasonable precautions to protect your Personal Information from any loss or unauthorized use, access or disclosure. Except for the aforementioned, we shall not share the provided data with any other third parties.
        </Typography>
        <Typography sx={{
            fontSize: '16px',
            fontWeight: 300,
            lineHeight: '130%',
        }}>
        The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
        </Typography>
    </Typography>
  )
}

export default Security